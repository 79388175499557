<template>
  <page>
    <Collection   name="my-workorder"
        schemaMode='1'
        v-slot="{ schemas }" >
        <p style="display:none">{{setsch(schemas)}}</p>
      </Collection>
   <div class="top_bar" id="hotboxdiv">
    <a-card>
      <a-row>
        <a-col :span="2" :offset="0" class="user_pic">
          <a-badge status="success" >
            <a-avatar :size="64" icon="user" src="https://tva1.sinaimg.cn/large/008eGmZEly1gmvck7vdwlj30hs0hst9b.jpg"/>
          </a-badge>
        </a-col>
        <a-col :span="9" class="user_info">
          <p><a-icon type="idcard"/><strong>电话: {{ this.$Cookies.get("phone") }}</strong></p>
          <p><a-icon type="smile"/><strong> 话务员: {{ this.$Cookies.get("user_name") }}</strong></p>
<!--          <p><a-icon type="bulb"/><strong> 昵称: sankinnk</strong></p>-->
        </a-col>
        <a-col :span="10" class="Statistics">
          <div class="Statistics-data float-le pr-3">
            <p><a-icon type="stock" />今日接待:</p>
            <p class="number">34</p>
          </div>
          <div class="Statistics-data float-le pr-3">
            <p><a-icon type="pie-chart" />历史接待:</p>
            <p class="number">84867</p>
          </div>
        </a-col>
        <a-col :span="2" class="message">
          <a-col class="float-left message-n">
            <a-badge count="5">
            <a-icon type="message"/>
          </a-badge>
          </a-col>
          <a-col class="float-left emal">
            <a-badge count="5">
            <a-icon type="mail"/>
           </a-badge>
          </a-col>
        </a-col>
      </a-row>
    </a-card>
    </div>
    <div class="panel-content">
      <div class="filter-items">
        <div class="grid-wrap mt-4">
          <div class="col-md-8 grid-item col-sm-8 col-lg-8">
            <div class="widget pad50-65">
              <!-- 录入表单信息 -->
              <a-form-model :model="userinfo"  ref="ruleForm">
                <a-row>
                  <a-col :span="24">
                    <div class="widget-title2">
                      <h4><a-divider orientation="left">
                        <a-icon type="idcard" class="body_ueser_info" />基本信息
                      </a-divider></h4>
                    </div>
                  </a-col>
                </a-row>
                <a-row :gutter="16">
                  <a-col :span="12" >
                    <a-form-model-item prop="user_name">
                      <a-input type="text" placeholder="请输入姓名" :value='userinfo.user_name' />
                    </a-form-model-item>
                  </a-col>
                  <a-col :span="12">
                    <a-form-model-item>
                      <strong>性别 </strong>
                      <a-radio-group default-value="1">
                        <a-radio value="1"> 男 </a-radio>
                        <a-radio value="0"> 女 </a-radio>
                      </a-radio-group>
                    </a-form-model-item>
                  </a-col>
                </a-row>
                <a-row :gutter="16">
                  <a-col :span="12" >
                    <a-form-model-item  prop="phone">
                      <a-input type="text" placeholder="联系电话"  :value='userinfo.phone'/>
                    </a-form-model-item>
                  </a-col>
                  <a-col :span="12">
                    <a-form-model-item>
                            <a-select  placeholder="人员类型">
                               <a-select-option value=''>请选择</a-select-option>
                              <a-select-option v-for="value in ordermod.person_type" :key="value.item_value"  :value="value.item_value">
                               {{value.item_name}}
                              </a-select-option>
                            </a-select>
                    </a-form-model-item>
                  </a-col>
                </a-row>
                <a-row :gutter="16">
                  <a-col :span="12" >
                    <a-form-model-item>
                      <a-input type="text" placeholder="来电号码"  :value='userinfo.phone'/>
                    </a-form-model-item>
                  </a-col>
                  <a-col :span="12">
                    <a-form-model-item>
                      <a-select  placeholder="地区">
                               <a-select-option value=''>请选择</a-select-option>
                              <a-select-option v-for="value in cityarr" :key="value.district_id"  :value="value.district_id">
                               {{value.district_name}}
                              </a-select-option>
                            </a-select>
                    </a-form-model-item>
                  </a-col>
                </a-row>
                <a-row :gutter="16">
                  <a-col :span="12" >
                    <a-form-model-item>
                      <a-input type="text" v-model="formdata.address" placeholder="街道地址" />
                    </a-form-model-item>
                  </a-col>
                  <a-col :span="12">
                    <a-form-model-item>
                            <a-select  placeholder="汉族">
                               <a-select-option value=''>请选择</a-select-option>
                              <a-select-option v-for="value in ordermod.nation" :key="value.item_value"  :value="value.item_value">
                               {{value.item_name}}
                              </a-select-option>
                            </a-select>
                    </a-form-model-item>
                  </a-col>
                </a-row>
                <a-row>
                  <a-col :span="24">
                    <div class="widget-title2">
                      <h4><a-divider orientation="left">
                        <a-icon type="diff" class="body_ueser_info" />咨询详情
                      </a-divider></h4>
                    </div>
                  </a-col>
                </a-row>
                <div class="typeboxs">
                 <div class="item3">
                      <a-select
                        placeholder="咨询类别" @change="setordertype" default-value="咨询"
                      >
                        <a-select-option v-for=" value in ordermod.order_type" :key="value.item_value" :value="value.item_value">{{value.item_name}}</a-select-option>
                      </a-select>
                 </div>
                  <div class="item3">
 <Collection  name="service_type"  v-slot="{ collection }" :filter="{ parent: ordertype }"  :limit="1000">
                      <a-select
                        placeholder="服务类型" @change="setServicetype"
                      >
                        <a-select-option v-for=" value in collection" :key="value.item_value" :value="value.item_value">{{value.item_name}}</a-select-option>
                        <a-select-option value="beijing"> 其他 </a-select-option>
                      </a-select>
                 </Collection>
                  </div>
                  <div class="item3">
 <Collection  name="business_type"  v-slot="{ collection }" :filter="{ parent: service_type }"  :limit="1000">
                      <a-select
                        placeholder="业务类型" @change="setBusinesstype"
                      >
                        <a-select-option v-for=" value in collection" :key="value.item_value" :value="value.item_value">{{value.item_name}}</a-select-option>
                        <a-select-option value="beijing"> 其他 </a-select-option>
                      </a-select>
                 </Collection>
                  </div>
                  <div class="clear"></div>
                </div>
                 <div v-if="ordertype === '预约'">
                     <a-col :span="12" >
                   <a-form-model-item>
                      <a-date-picker @change="onChangedate" placeholder='请选择预约时间' />
                  </a-form-model-item>
                     </a-col>
                  <div class="item3">
                  </div>
                  <div class="item3">
                  </div>
                  <div class="clear"></div>
                </div>
                <a-row :gutter="16">
                  <a-col :span="12" >
                    <a-form-model-item  prop="order_desc">
                      <template>
                        <a-textarea  v-model="formdata.order_desc"  placeholder="备注信息" :rows="4" style="height: 100px"/>
                      </template>
                    </a-form-model-item>
                  </a-col>
                  <a-col :span="12">
                    <a-form-model-item  prop="order_comment">
                      <template>
                        <a-textarea v-model="formdata.reply_desc" placeholder="答复意见" :rows="4"  style="height: 100px"/>
                      </template>
                    </a-form-model-item>
                  </a-col>
                </a-row>
                <a-row>
                  <a-col >
                      <a-button class="subdivright" @click="handleSubmit" type="primary" html-type="submit">
                        <a-icon type="check" /> 提交
                      </a-button>
                  </a-col>
                </a-row>
              </a-form-model>
            </div>
            <div class="widget proj-order pad50-40">
              <a-row>
                <a-col :span="24">
                  <div class="widget-title2">
                    <h4>
                      <a-divider orientation="left">
                        <a-icon type="file-search"  class="body_ueser_info"/>历史来电
                      </a-divider>
                    </h4>
                  </div>
                </a-col>
              </a-row>
              <div class="table-wrap">
                <a-row>
                  <a-col :span="24">
                    <a-table :columns="columns" :data-source="telhist">
                    </a-table>
                  </a-col>
                </a-row>
              </div>
            </div>
          </div>
          <div class="col-md-4 grid-item col-sm-4 col-lg-4">
            <div class="widget recently-activ-proj">
              <div class="tab-styl">
                <a-tabs default-active-key="1">
                  <a-tab-pane key="1">
                    <p slot="tab" class="right_title"><a-icon type="schedule" />服务档案</p>
                    <div class="message-widgetbox">
                      <p><a-icon type="user" style="font-size: 15px;"/>
                      {{userinfo.user_name}}
                      </p>
                        <p>
                          <a-icon type="idcard" style="font-size: 15px;"/>
                          身份证:  {{userinfo.user_name}}
                        </p>
                        <p>
                          <a-icon type="team" style="font-size: 15px;"/>
                          户籍所在地:
                        </p>
                        <p>
                          <a-icon type="phone" style="font-size: 15px;"/>
                          联系电话:{{userinfo.phone}}
                        </p>
                    </div>
                    <div class="slimScrollDiv pt-4" >
                      <a-timeline>
                        <a-timeline-item v-for="value in workerlist" :key="value.workorder_id">
                         <p>{{ showordertype(value.order_type)}}</p>
                         {{moment(value.create_time).format('YYYY-MM-DD')}}-{{value.order_desc}}<span class="state">
                          <a-tag :color="findColor(value.status)">{{value.status}}</a-tag>
                        </span></a-timeline-item>
                      </a-timeline>
                    </div>
                  </a-tab-pane>
                  <a-tab-pane key="2">
                    <p slot="tab" class="right_title"><a-icon type="bank" />机构查询</p>
                    <div class="message-widget form-wrp mt-4">
                      <div class="wight-judges">
                        <a-row>
                          <div class="itemrows">
                            <Collection  name="provider_type"  v-slot="{ collection }"  :query="{ _size: 1000 }">
                            <a-select  class="wight-judge" name="provider_type" placeholder="机构类型" @change="setprovider_type">
                              <a-select-option v-for="value in collection" :key="value.item_value"  :value="value.item_value">
                               {{value.item_name}}
                              </a-select-option>
                            </a-select>
                            </Collection>
                          </div>
                         <div class="itemrows">
                             <Collection  name="city"  v-slot="{ collection }" >
                            <a-select  placeholder="市区" @change="handleChange($event, collection)">
                               <a-select-option value=''>请选择</a-select-option>
                              <a-select-option v-for="value in collection" :key="value.district_id"  :value="value.district_id">
                               {{value.district_name}}
                              </a-select-option>
                            </a-select>
                            </Collection>
                         </div>
                          <div class="itemrows" v-if="citykey !== ''">
                             <Collection  name="area"    v-slot="{ collection }" :filter="{ parent: citykey }" >
                            <a-select  placeholder="县"  @change="areaChange($event, collection)">
                               <a-select-option value=''>请选择</a-select-option>
                              <a-select-option v-for="value in collection" :key="value.district_id" :value="value.district_id">
                               {{value.district_name}}
                              </a-select-option>
                            </a-select>
                            </Collection>
                          </div>
                          <div class="itemrows">
                             <div>
                                <a-radio-group v-model="otype" default-value="1" button-style="solid" @change="changeOtype">
                                  <a-radio-button value="1">
                                    机构
                                  </a-radio-button>
                                  <a-radio-button value="2">
                                    人员
                                  </a-radio-button>
                                </a-radio-group>
                              </div>
                          </div>
                        </a-row>
                      </div>
                      <div class="group" v-if="otype == '1'">
                      <div v-for="value in prodata"  :key="value.provider_id" :bordered="false" class="wight-judgeorg">
                        <h3><a-icon class="orgico" type="home" />{{value.provider_name}}</h3>
                        <p>
                          联系地址:{{value.address}}
                        </p>
                        <p>
                          联系电话:{{value.phone}}
                        </p>
                      </div>
                      </div>
                       <div class="peo" v-if="otype == '2'">
                      <div  :bordered="false" v-for="value in peodata" :title="value.person_name" :key="value.person_id" class="wight-judgeorg">
                        <h3><a-icon class="orgico" type="user" />{{value.person_name}}</h3>
                        <p>
                          {{value.provider_name}}
                        </p>
                        <p>
                          联系电话:{{value.phone}}
                        </p>
                      </div>
                       </div>
                    </div>
                  </a-tab-pane>
                  <a-tab-pane key="3">
                    <p slot="tab" class="right_title"><a-icon type="file-protect" />知识库</p>
                    <div class="message-widget form-wrp mt-4">
                      <div class="wight-judges">
                        <div class="itemrows">
                           <Collection  name="worktype"  v-slot="{ collection }" >
                            <a-select  placeholder="请选择类型" @change="asktype">
                              <a-select-option value=''>请选择</a-select-option>
                              <a-select-option v-for="value in collection" :key="value.item_name"  :value="value.item_name">
                               {{value.item_name}}
                              </a-select-option>
                            </a-select>
                            </Collection>
                        </div>
                         <div class="itemrows">
                          <a-input-search placeholder="输入您要了解的问题" @keyup.enter="searchask"  v-model="que_title"  />
                         </div>
                    </div>
                    <div title="结果" :bordered="false" class="wight-judgeorg">
                      <a-list item-layout="horizontal" :data-source="askdata">
                        <a-list-item  slot="renderItem" slot-scope="item">
                          <a-list-item-meta
                            :description="item.que_result"
                          >
                            <a slot="title">{{ item.que_title }}</a>
                          </a-list-item-meta>
                        </a-list-item>
                      </a-list>
                    </div>
                    </div>
                  </a-tab-pane>
                </a-tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </page>
</template>
<script>
import Collection from '../../components/Collection.vue'
import model from '../../core/model.js'
export default {
  name: 'hotline',
  mixins: [model],
  components: {
    Collection
  },
  data () {
    var telphone = this.$route.query.phone
    var userinfo = {}
    userinfo.phone = telphone
    var columns = [
      {
        title: '描述',
        dataIndex: 'order_desc'
      },
      {
        title: '来电时间',
        dataIndex: 'create_time',
        customRender: function (text, record, index) {
          var date = new Date(text)
          var y = date.getFullYear()
          var m = date.getMonth() + 1
          var d = date.getDate()
          var h = date.getHours()
          var i = date.getMinutes()
          return y + '-' + m + '-' + d + ' ' + h + ':' + i
        }
      },
      {
        title: '等级',
        dataIndex: 'order_level',
        customRender: function (text, record, index) {
          if (text) {
            var textvalue = '高'
            if (text === 2) {
              textvalue = '中'
            }
            if (text === 1) {
              textvalue = '低'
            }
            return textvalue
          }
        }
      },
      {
        title: '状态',
        dataIndex: 'status'
      }
    ]
    var formdata = {
      address: '',
      area: '',
      city: '兰州市',
      person_type: '',
      platform_channel: '热线',
      platform_type: '热线',
      sponsor_phone: ''
    }
    return {
      citykey: '',
      city: '',
      cityarr: [],
      area: '',
      formdata: formdata,
      otype: '1',
      workorder: {},
      provider_type: '',
      ordertype: '咨询',
      service_type: '',
      que_title: '',
      asktypevalue: '',
      prodata: [],
      peodata: [],
      askdata: [],
      telphone: telphone,
      userinfo: {},
      workerlist: [],
      telhist: [],
      columns: columns,
      ordermod: [],
      form: {},
      color: [
        {
          status: '已受理',
          color: '#f50'
        },
        {
          status: '已委派人员',
          color: '#2db7f5'
        },
        {
          status: '已委派机构',
          color: '#87d068'
        },
        {
          status: '办理中',
          color: '#973cb7'
        },
        {
          status: '完成',
          color: '#ff5500'
        },
        {
          status: '已申请',
          color: '#2b79d7'
        },
        {
          status: '上门',
          color: '#37d7bf'
        },
        {
          status: '关闭',
          color: '#f6223b'
        },
        {
          status: '待办理',
          color: '#1fbec3'
        },
        {
          status: '已回复',
          color: '#9ed439'
        },
        {
          status: '已提问',
          color: '#89cef3'
        }
      ]
    }
  },
  mounted () {
    // this.searchorg()
    this.getuserinfo()
    this.getworkorder()
  },
  methods: {
    setsch (value) {
      this.ordermod = value
      var cityarr = value.city
      if (typeof cityarr === 'object') {
        var city = cityarr.filter(function (currentValue, index, arr) {
          console.log(currentValue)
          if (currentValue.parent === 'XX省') {
            return true
          } else {
            return false
          }
        })
        this.cityarr = city
      }
      console.log(city)
    },
    momentinfo (value) {
      return this.moment(value).format('YYYY-MM-DD')
    },
    showordertype (id) {
      var obj = {}
      var ordertypearr = this.ordermod.order_type
      obj = ordertypearr.find((item) => {
        return item.item_value === id
      })
      return obj.item_name
    },
    setordertypedata (data) {
      this.typedata = data
    },
    getworkorder () {
      var that = this
      this.$api.get('/schemas/workorder').then(function (res) {
        that.workorder = res.data.model
      })
    },
    getuserinfo () {
      var that = this
      if (this.telphone === '') {
        this.$message.error('手机号码不能为空')
      }
      this.$api.get('/api/getuser?phone=' + this.telphone).then(function (res) {
        var uinfo = res.data.collection[0]
        that.userinfo = uinfo
        that.getusertelhist(uinfo)
        // that.getuserworker(uinfo)
      })
    },
    getusertelhist (uinfo) {
      var that = this
      var str = '?'
      str += 'sponsor_phone=' + that.telphone
      this.$api.get('/api/workorder' + str).then(function (res) {
        var workerlist = res.data.collection
        var telhist = []
        var myworklist = []
        workerlist.forEach(function (item, index) {
          if (item.platform_channel === '热线') {
            telhist.push(item)
          } else {
            myworklist.push(item)
          }
        })
        that.telhist = telhist
        that.workerlist = myworklist
      })
      // 01769e24b00e8a838286769d2ec704d8
    },
    getuserworker (uinfo) {
      var that = this
      var str = '?platform_channel=热线'
      str += '&sponsor_phone=' + that.telphone
      this.$api.get('/api/workorder' + str).then(function (res) {
        that.telhist = res.data.collection
      })
    },
    handleSubmit (e) {
      var that = this
      var formdata = this.formdata
      formdata.order_type = this.ordertype
      formdata.service_type = this.service_type
      formdata.business_type = this.business_type
      formdata.sponsor_name = this.userinfo.user_name
      formdata.sponsor_phone = this.userinfo.phone
      formdata.sponsor = this.userinfo.uid
      var url = 'my-workorder/writeaccept'
      if (this.service_type !== '咨询') {
        url = 'my-workorder/apply'
      }
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          var result = that.saveData({ url: url, data: formdata })
          if (result) {
            that.$message.success('完成提交')
            that.formdata.order_desc = ''
            that.formdata.reply_desc = ''
            that.getuserworker()
            // 数据初始化
          }
        } else {
          return false
        }
      })
    },
    setServicetype (value) {
      this.service_type = value
    },
    setordertype (value) {
      this.ordertype = value
    },
    setBusinesstype (value) {
      this.business_type = value
    },
    handleChange (value, options) {
      this.citykey = value
      var obj = {}
      obj = options.find((item) => {
        return item.district_id === value
      })
      this.city = obj.district_name
      this.searchorg()
    },
    areaChange (value, options) {
      var obj = {}
      obj = options.find((item) => {
        return item.district_id === value
      })
      this.area = obj.district_name
      this.searchorg()
    },
    searchask () {
      this.getask()
    },
    asktype (value) {
      this.asktypevalue = value
      this.getask()
    },
    changeOtype (value) {
      this.searchorg()
    },
    setprovider_type (value) {
      this.provider_type = value
      this.searchorg()
    },
    searchorg () {
      var that = this
      var str = '?'
      str += 'area=' + this.area + '&city=' + this.city + '&provider_type=' + this.provider_type
      if (this.otype === '2') {
        this.$api.get('/api/person' + str).then(function (res) {
          that.peodata = res.data.collection
        })
      } else {
        this.$api.get('/api/provider' + str).then(function (res) {
          that.prodata = res.data.collection
        })
      }
    },
    getask () {
      var that = this
      var str = '?'
      str += 'que_type=' + this.asktypevalue + '&que_title=' + this.que_title
      this.$api.get('/api/ask' + str).then(function (res) {
        that.askdata = res.data.collection
      })
    },
    findColor (value) {
      var obj = this.color.find((item) => {
        return item.status === value
      })
      return Object.assign({}, obj).color
    }
  }
}
</script>
<style scoped src="../../assets/css/hotline.css"></style>
